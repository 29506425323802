import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
} from "@angular/core";
import { ImageService } from "../../services/image.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25ImageService } from "../s25-image/s25.image.service";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { Bind } from "../../decorators/bind.decorator";

@TypeManagerDecorator("s25-ng-image-form")
@Component({
    selector: "s25-ng-image-form",
    template: `
        <s25-loading-inline model="{}"></s25-loading-inline>
        @if (this.init) {
            <div class="image-form-wrapper">
                <label class="ngInlineBlock c-margin-top--half c-margin-right--double">
                    <span class="ngBold ngInlineBlock c-margin-bottom--quarter">Name</span>
                    <s25-ng-editable-text
                        [max]="40"
                        [allowEmpty]="false"
                        [alwaysEditing]="true"
                        [(val)]="this.model.itemName"
                    ></s25-ng-editable-text>
                </label>
                <label class="ngInlineBlock c-margin-top--half c-margin-right--double">
                    <span class="ngBold ngInlineBlock c-margin-bottom--quarter">Type</span>
                    <s25-generic-dropdown
                        [items]="types"
                        [placeholder]="'Select a type'"
                        [(chosen)]="model.itemType"
                        (chosenChange)="chooseType($event)"
                    ></s25-generic-dropdown>
                </label>
                <div>
                    <label class="ngInlineBlock c-margin-top--half c-margin-right--double">
                        <span class="ngBold ngInlineBlock c-margin-bottom--quarter">Description</span>
                        <s25-ng-editable-textarea
                            [allowEmpty]="true"
                            [alwaysEditing]="true"
                            [(val)]="model.itemDesc"
                        ></s25-ng-editable-textarea>
                    </label>
                    <s25-ng-image-upload
                        [imageUri]="this.url"
                        [hasCropper]="hasCropper"
                        (uploadedImageChange)="updateImage($event)"
                        [(model)]="imageModel"
                    ></s25-ng-image-upload>
                    @if (errorMsg) {
                        <div class="ngBold ngRed">{{ errorMsg }}</div>
                    }
                </div>
            </div>
            <div class="button-wrapper c-margin-top--half c-margin-bottom--half">
                <s25-ng-button [type]="'primary'" [onClick]="save">Save</s25-ng-button>
                <s25-ng-button [type]="'outline'" (click)="close('cancel')">Cancel</s25-ng-button>
            </div>
        }
    `,
    styles: `
        ::ng-deep .image-form-wrapper {
            width: 90%;
            margin: auto;
        }

        ::ng-deep .s25-image-body {
            display: flex;
            flex-direction: column;
            gap: 3px;
            width: fit-content;
            margin: 1rem auto;
            padding: 1rem;
            box-shadow:
                rgba(0, 0, 0, 0.05) 0 6px 24px 0,
                rgba(0, 0, 0, 0.08) 0 0 0 1px;
        }

        ::ng-deep .upload-btn-wrapper {
            margin-left: 10px;
        }

        ::ng-deep .button-wrapper {
            display: flex;
            gap: 1rem;
            justify-content: center;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageFormComponent implements OnInit {
    @Input() itemId: number;
    @Input() hasCropper: false;
    @Input() onSave: any; //function
    @Input() cancel: any; //function

    model: any;
    imageModel: any = {};
    url: string;
    init = false;
    errorMsg: string;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {}

    types = [
        {
            itemId: 1,
            itemName: "Photograph",
        },
        {
            itemId: 2,
            itemName: "Diagram",
        },
    ];

    ngOnInit(): void {
        this.elementRef.nativeElement.angBridge = this;

        if (this.itemId) {
            S25LoadingApi.init(this.elementRef.nativeElement);
            S25Util.all({
                data: ImageService.getImage(this.itemId),
                imageUrl: S25ImageService.getUrl(this.itemId, 400),
            }).then((resp) => {
                let data = resp.data;
                this.url = resp.imageUrl;
                this.model = {
                    itemId: data.image_id,
                    itemName: data.image_name,
                    itemDesc: data.image_desc,
                    itemType: {
                        itemId: data.image_type,
                        itemName: data.image_type_name,
                    },
                    fileName: data.file_name,
                    imageData: data.image_data,
                };
                this.init = true;
                S25LoadingApi.destroy(this.elementRef.nativeElement);
                this.cd.detectChanges();
            });
        } else {
            this.model = {
                itemId: null,
                itemName: "",
                itemDesc: "",
                itemType: {
                    itemId: 1,
                },
                fileName: "",
                imageData: null,
            };
            this.init = true;
            this.cd.detectChanges();
        }
    }

    updateImage(base64ImgStr: string) {
        this.model.fileName = this.imageModel.getImageFileName();
        this.model.imageData = base64ImgStr;
    }

    chooseType(data: any) {}

    @Bind
    save() {
        if (this.validate()) {
            return ImageService.setImage(
                this.model.itemId,
                this.model.itemName,
                this.model.itemDesc,
                this.model.fileName,
                this.model.imageData,
                this.model.itemType.itemId,
            ).then(
                (resp) => {
                    this.close("save");
                },
                (err) => {
                    S25Util.showError(err);
                },
            );
        } else return Promise.resolve(); // the button comp expects a promise to resolve the "onClick" event and re-enable button use
    }

    close(type: string) {
        if (type === "save") {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            this.onSave && this.onSave();
        } else if (type === "cancel") {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            this.cancel && this.cancel();
        }
    }

    validate() {
        this.errorMsg = "";
        if (!this.model.itemName) {
            this.errorMsg = "Please enter an image name";
        } else if (!this.model.imageData) {
            this.errorMsg = "Please upload a valid image";
        }
        return !this.errorMsg;
    }
}
