//@author: travis

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageFormComponent } from "./s25.image.form.component";
import { FormsModule } from "@angular/forms";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25ImageUploadModule } from "../s25-image-upload/s25.image.upload.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25ButtonComponent } from "../../standalone/s25.button.component";

@NgModule({
    declarations: [ImageFormComponent],
    imports: [
        CommonModule,
        FormsModule,
        S25EditableModule,
        S25DropdownPaginatedModule,
        S25ImageUploadModule,
        S25LoadingInlineModule,
        S25ButtonComponent,
    ],
    providers: [ImageFormComponent],
    exports: [ImageFormComponent],
})
export class S25ImageFormModule {
    constructor() {}
}
