//@author: devin
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    NgZone,
    Renderer2,
    ViewEncapsulation,
} from "@angular/core";
import { S25ItemComponent } from "../s25.item.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-item-contact")
@Component({
    selector: "s25-item-contact",
    template: `
        @if (init) {
            <s25-item
                [modelBean]="modelBean"
                [includeTypeIcon]="includeTypeIcon"
                [popoverTemplate]="contactTooltip"
                [newTab]="newTab"
            ></s25-item>
        }

        <ng-template #contactTooltip>
            <div class="qtip-content">
                @if (!modelBean.initPopOver) {
                    <div>Loading...</div>
                }
                @if (modelBean.initPopOver && !modelBean.data?.item) {
                    <div class="contactBox popoverroot">Could not load contact details</div>
                }
                @if (modelBean.initPopOver && modelBean.data?.item) {
                    <div class="contactBox popoverroot">
                        <div
                            class="boxHeader"
                            (contextmenu)="contextMenu({ itemTypeId: 3, itemId: modelBean.itemId }, $event)"
                        >
                            <div class="boxHeaderName">{{ modelBean.data.item.name }}</div>
                        </div>
                        <div class="boxBody">
                            @if (modelBean.data.item.title) {
                                <table cellspacing="0" width="100%">
                                    <tr>
                                        <td>Title:</td>
                                        <td>{{ modelBean.data.item.title }}</td>
                                    </tr>
                                </table>
                            }
                            @for (row of modelBean.data.item.rows; track trackByFn($index, row)) {
                                <table cellspacing="0" width="100%">
                                    @if (row.address) {
                                        <tr>
                                            <td>Address:</td>
                                            <td>
                                                {{ row.address }}
                                                <span class="note">
                                                    @if (row.addr_type == 3) {
                                                        <span>Work</span>
                                                    }
                                                    @if (row.addr_type == 4) {
                                                        <span>Home</span>
                                                    }
                                                    ()
                                                </span>
                                            </td>
                                        </tr>
                                    }
                                    @if (row.phone) {
                                        <tr>
                                            <td>Phone:</td>
                                            <td>
                                                {{ row.phone }}
                                                <span class="note"
                                                    >(
                                                    @if (row.addr_type == 3) {
                                                        <span>Work</span>
                                                    }
                                                    @if (row.addr_type == 4) {
                                                        <span>Home</span>
                                                    }
                                                    )</span
                                                >
                                            </td>
                                        </tr>
                                    }
                                    @if (row.fax) {
                                        <tr>
                                            <td>Fax:</td>
                                            <td>
                                                {{ row.fax }}
                                                <span class="note"
                                                    >(
                                                    @if (row.addr_type == 3) {
                                                        <span>Work</span>
                                                    }
                                                    @if (row.addr_type == 4) {
                                                        <span>Home</span>
                                                    }
                                                    )</span
                                                >
                                            </td>
                                        </tr>
                                    }
                                    @if (row.e_mail) {
                                        <tr>
                                            <td>Email:</td>
                                            <td>
                                                <a href="mailto:{{ row.e_mail }}">{{ row.e_mail }}</a>
                                                <span class="note"
                                                    >(
                                                    @if (row.addr_type == 3) {
                                                        <span>Work</span>
                                                    }
                                                    @if (row.addr_type == 4) {
                                                        <span>Home</span>
                                                    }
                                                    )</span
                                                >
                                            </td>
                                        </tr>
                                    }
                                </table>
                            }
                        </div>
                    </div>
                }
            </div>
        </ng-template>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ItemContactComponent extends S25ItemComponent {
    constructor(
        elementRef: ElementRef,
        zone: NgZone,
        cd: ChangeDetectorRef,
        componentFactoryResolver: ComponentFactoryResolver,
        renderer: Renderer2,
    ) {
        super(elementRef, zone, cd, componentFactoryResolver, renderer);
    }

    ngOnInit() {
        this.itemTypeId = 3;
        super.ngOnInit();
    }
}
