//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25ImageComponent } from "./s25.image.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25ImageCopyLinkComponent } from "./s25.image.copy.link.component";
import { S25ImagePreviewComponent } from "./s25.image.preview.component";
import { PopoverModule } from "../s25-popover/popover.module";

@NgModule({
    declarations: [S25ImageComponent, S25ImageCopyLinkComponent, S25ImagePreviewComponent],
    imports: [CommonModule, FormsModule, S25LoadingInlineModule, PopoverModule],
    providers: [S25ImageComponent, S25ImageCopyLinkComponent, S25ImagePreviewComponent],
    exports: [S25ImageComponent, S25ImageCopyLinkComponent, S25ImagePreviewComponent],
})
export class S25ImageModule {
    constructor() {}
}
